.chatting-entire-container {
  width: 1200px;
  height: 778px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  font-family: 'PretendardVariable';

  display: flex;
  justify-content: center;
  align-items: center;
}

.chatting-list-container {
  border: 1px solid #f6f6f6;
  width: 425px;
  height: 666px;
  overflow: hidden;
}

.chatting-list-tit {
  border-bottom: 1px solid #f6f6f6;
  width: 100%;
  height: 75px;
  position: inherit;
}

.chatting-list-tit h3 {
  float: left;
  padding: 0;
  margin: 0 0 0 15px;
  font-weight: 600;
  font-size: 16px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chatting-list-members-icons {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 12px;
  margin-right: 15px;
}

/* 멤버카드시작 */
.chatting-list-member-card {
  display: flex;
  flex-direction: row;
}
.chatting-list-members {
  padding: 12px 0 0 12px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  gap: 18px;
}
.member-card-right {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.member-card-top {
  display: flex;
  flex-direction: row;
}
.member-card-right-top h4,
.member-card-right-top p {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.member-card-right-top h4 {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}
.member-card-right-top p {
  font-weight: 400;
  font-size: 12px;
  color: #a6a6a6;
  margin-left: 10px;
}
.member-card-right-bottom p {
  text-overflow: ellipsis;
  margin-top: 3px;
}

.member-card-right-bottom {
  color: #a6a6a6;
  font-weight: 400;
  font-size: 14px;
}
/* 멤버카드끝 */

/* 메세지영역시작 */
.chatting-msg-container {
  border: 1px solid #f6f6f6;
  width: 743px;
  height: 666px;
}

.chatting-msg-opposite-info {
  border-bottom: 1px solid #f6f6f6;
  position: relative;
  width: 100%;
  display: flex;
  height: 75px;
  justify-content: space-between;
}

.user-info {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-left: 20px;
}

.user-info p {
  font-weight: 600;
  font-size: 16px;
  margin-left: 7px;
}
.user-info-more {
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  margin-right: 30px;
}

.chatting-other-msg-card {
  width: 328px;
  display: flex;
  flex-direction: row;
  margin: 10px 0 35px 10px;
}
.other-msg-card-left {
  padding: 15px 0 0 0px;
}
.other-msg-card-left img {
  width: 40px;
  height: 40px;
}
.other-msg-card-right {
  margin-left: 5px;
}
.other-msg-card-right h3 {
  font-weight: 400;
  font-size: 14px;
  color: #333;
}
.other-msg-card-right-content {
  background: #f6f6f6;
  border-radius: 4px 14px 14px 14px;
  width: auto;
  height: auto;
}
.other-msg-card-right-content p {
  font-weight: 400;
  font-size: 14px;
  color: #333;
  padding: 10px;
}
.chatting-my-msg-card-container {
  width: 100%;
}
.chatting-my-msg-card-container .chatting-my-msg-card{
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 10px;
}

.chatting-my-msg-card {
  margin-top: 10px;
  width: 276px;
}
.my-msg-card-right-content {
  border-radius: 14px 4px 14px 14px;
  background: #4c9f8b;
  width: auto;
  height: auto;
}
.chatting-my-msg-card p {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  padding: 10px;
}

.chatting-msg-container-wrapper {
  height: 470px;
  overflow-y: scroll;
}

.chatting-msg-send-container {
  display: flex;
  flex-direction: row;
  height: 115px;
  width: 100%;
  justify-content: space-between;
}

.chatting-msg-send-content {
  position: relative;
  height: 100px;
  margin-left: 8px;
}
.chatting-msg-send-content img {
  position: absolute;
  left: 10px;
  bottom: 13px;
}
.chatting-msg-send-content textarea {
  width: 600px;
  height: 100%;
  outline: none;
  border: 1px solid #f2f2f2;
  font-family: 'PretendardVariable';
  padding: 4px 0 0 4px;

  border-radius: 8px;
  padding-left: 5px;
}
.chatting-msg-send-btn {
  width: 100%;
}

.chatting-msg-send-btn button {
  width: 84px;
  height: 40px;
  background: #82d3ac;
  border-radius: 8px;
  color: #fff;
  font-family: 'PretendardVariable';
  font-weight: 600;
  font-size: 14px;
  border: none;
  margin-left: 5px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 4px; /* 세로축 스크롤바 폭 너비 */
  height: 1px; /* 가로축 스크롤바 폭 너비 */
} /* 스크롤바 막대 */
::-webkit-scrollbar-thumb {
  background: #82d3ac; /* 스크롤바 막대 색상 */
  border-radius: 12px 12px 12px 12px;
}

/* 스크롤이 움직이는 뒷 배경 */
::-webkit-scrollbar-track {
  /* background: #d0d0d0;  */
  /* 스크롤바 뒷 배경 색상 */
}


/* renew */
.chatlist-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  width: 500px;
  margin: 0 auto;
}

.chat-tab {
  display: flex;
  width: 100%;
}
.chat-tab .tab-item:hover {
  background-color: #a9fad3;
}
.chat-tab .tab-item {
  width: 100%;
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.chat-content {
  width: 100%;
}

.chatlist-container ul {
  margin: 0;
  padding: 0;
}
.chatlist-container ul li {
  list-style: none;
  background-color: #82d3ac;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #6aa689;
  padding: 20px;
}

.chat-content input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 10px;
  margin-top: 10px;
}

.chat-content button:hover {
  color: rgb(94, 94, 94);
  background-color: #a9fad3;
  border-radius: 10px;
}

.chat-content button {
    margin-top: 10px;
    background: none;
    cursor: pointer;
    border: none;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    font-size: 20px;
}

.sending-area {
  position: absolute;
  bottom: 1%;
  left: 30%;
}
.sending-area input{
  width: 500px;
  height: 70px;
  border: 1px solid #a9fad3;
  padding: 10px;
}
.sending-area button{
  border: 1px solid #a9fad3 dotted;
  background-color: none;
  margin-left:30px;
}

.chat-makeroom-container.show {
  display: block;
}

.chat-makeroom-container {
  display: none;
  z-index: 999999;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */
  
}
.chat-makeroom-content {
  background-color: #fff;
  border-radius: 10px;
  margin: 15% auto; /* Center the modal */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be any size */
  max-width: 500px; /* Maximum width */
  text-align: center;
  height: 300px;
  width: 600px;
}

.chat-makeroom-content ul li {
  font-weight: 600;
  margin-right: auto;
}

ul {
  margin: 0;
  padding: 0;
}